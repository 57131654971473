<template>
  <div class="app-container" :style="{ backgroundImage: `url(${backgroundImage})` }">
    <div class="side-text left">
      <div class="top-small-text">{{ leftTeam.name }}</div>
      <div class="center-large-number">{{ leftTeam.score }}</div>
    </div>
    <div class="side-text right">
      <div class="top-small-text">{{ rightTeam.name }}</div>
      <div class="center-large-number">{{ rightTeam.score }}</div>
    </div>
    <div class="bottom-quote">{{ footerText }}</div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { connect } from '@/api/websocket';

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Scoreboard',
  setup() {
    const leftTeam = ref({ name: '孙晓静组', score: 0 });
    const rightTeam = ref({ name: '樊美娇组', score: 0 });
    const footerText = ref('');
    const backgroundImage = ref('/default_background.jpg');

    const fetchData = async () => {
      try {
        const response = await fetch('http://47.95.200.171:8080/data/getOldData');
        if (!response.ok) {
          throw new Error('网络响应不是OK');
        }

        const data = await response.json();
        console.log(data);
        leftTeam.value = data.leftTeam;
        rightTeam.value = data.rightTeam;
        footerText.value = data.footerText;
      } catch (error) {
        console.error('获取数据失败:', error);
      }
    };

    onMounted(() => {
      fetchData();
      connect((message) => {
        leftTeam.value = message.leftTeam;
        rightTeam.value = message.rightTeam;
        footerText.value = message.footerText;
        if (message.backgroundImage) {
          backgroundImage.value = message.backgroundImage;
        }
      });
    });

    return {
      leftTeam,
      rightTeam,
      footerText,
      backgroundImage,
    };
  },
};
</script>

<style>
.app-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  background-size: cover;
  background-position: center;
  padding: 50px;
  min-height: 100vh;
  color: #FFFFFF;
}

.side-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  margin: 0 20px;
}

.left {
  position: absolute;
  left: 10%;
  top: 50%;
  transform: translateY(-50%);
}

.right {
  position: absolute;
  right: 10%;
  top: 50%;
  transform: translateY(-50%);
}

.top-small-text {
  font-size: 40px;
  color: #FFFFFF;
  text-shadow: 2px 2px 0  #c02526, 2px -2px 0  #c02526, -2px 2px 0  #c02526, -2px -2px 0  #c02526;
}

.center-large-number {
  font-size: 150px;
  font-weight: bold;
  color: #FFFFFF;
  text-shadow: 8px 8px 0  #c02526, 8px -8px 0  #c02526, -8px 8px 0  #c02526, -8px -8px 0  #c02526;
}

.bottom-quote {
  font-size: 40px;
  color: #f6dba1;
  text-shadow: 2px 2px 0  #c02526, 2px -2px 0  #c02526, -2px 2px  #c02526, -2px -2px 0  #c02526;
  margin-top: 20px;
  position: absolute;
  bottom: 80px;
}
</style>