// src/router/index.js
import { createRouter, createWebHistory } from 'vue-router';
import Home from '../views/Scoreboard.vue'; // 默认主页组件
import ChangeValue from '../views/changeValue.vue'; // 目标组件

const routes = [
    {
        path: '/',
        name: 'home',
        component: Home, // 默认主页
        meta: { title: '晨先业绩展示' }, // 设置页面标题
    },
    {
        path: '/changeValue',
        name: 'ChangeValue',
        component: ChangeValue, // 独立访问的组件
        meta: { title: '信息修改' }, // 设置页面标题
    },
];

const router = createRouter({
    history: createWebHistory(), // 使用 HTML5 History 模式
    routes,
});

export default router;