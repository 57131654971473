// src/main.js
import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css'; // 引入 Element Plus 样式

router.beforeEach((to, from, next) => {
    // 如果路由配置了 meta 中的 title，则设置为浏览器的标题
    if (to.meta.title) {
        document.title = to.meta.title;
    }
    next();
});

createApp(App)
    .use(router)
    .use(ElementPlus)
    .mount('#app');