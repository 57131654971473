import { io } from "socket.io-client";
import FingerprintJS from '@fingerprintjs/fingerprintjs';

// 异步获取设备指纹
async function getMachineCode() {
    const fp = await FingerprintJS.load();
    const result = await fp.get();
    return result.visitorId; // 返回唯一的设备指纹ID
}


export async function connect(callback) {
    const machineCode = await getMachineCode();
    // 创建 Socket.IO 客户端连接，替换 ws://localhost:10246/chat/1
    const socket = io("http://47.95.200.171:10246/", {
        transports: ["websocket"], // 强制使用 WebSocket 传输方式
        query: {auth: machineCode},
        rejectUnauthorized: false
    });

    // 连接成功时的处理
    socket.on("connect", () => {
        console.log("Socket.IO connection established");
        // 连接成功后可以进行消息发送或其他操作
        socket.emit('hello', {type: 'hello', content: 'Hello Server!'});
    });

    // 连接错误时的处理
    socket.on("connect_error", (error) => {
        console.error("Socket.IO error observed:", error);
    });

    // 连接关闭时的处理
    socket.on("disconnect", (reason) => {
        if (reason === 'io server disconnect') {
            // 服务器断开时的处理
            console.error('Server disconnected');
        } else {
            console.log(`Socket disconnected, reason=${reason}`);
        }
    });

    // 接收消息时的处理
    socket.on("message", (data) => {
        const message = JSON.parse(data);
        callback(message);
    });

    return socket;
}

