<template>
  <div class="form-container">
    <el-form :model="form" label-width="auto" style="max-width: 600px">
      <el-row>
        <el-form-item label="左侧队伍名称">
          <el-input v-model="form.liftName" />
        </el-form-item>
      </el-row>
      <el-row>
        <el-form-item label="右侧队伍名称">
          <el-input v-model="form.rightName" />
        </el-form-item>
      </el-row>
      <el-row>
        <el-form-item label="界面底部文字">
          <el-input
              type="textarea"
              style="width: 340px"
              :rows="4"
              v-model="form.name" />
        </el-form-item>
      </el-row>

      <el-button type="primary" @click="submitData"> 修改 </el-button>
    </el-form>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import axios from 'axios';

export default {
  setup() {
    const form = ref({
      liftName: '',
      rightName: '',
      name: ''
    });

    const fetchData = async () => {
      try {
        const response = await axios.get('http://47.95.200.171:8080/data/getData');
        Object.assign(form.value, response.data);
      } catch (error) {
        console.error('获取数据失败:', error);
      }
    };

    const submitData = async () => {
      try {
        await axios.put('http://47.95.200.171:8080/data/putData', form.value);
        alert('数据已提交！');
      } catch (error) {
        console.error('提交数据失败:', error);
      }
    };

    onMounted(() => {
      fetchData();
    });

    return {
      form,
      submitData
    };
  }
};
</script>

<style scoped>
.form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* 使容器高度占满视口 */
}
</style>