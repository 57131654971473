<!-- src/App.vue -->
<template>
  <div id="app">
    <router-view></router-view> <!-- 路由视图 -->
  </div>
</template>

<script>
export default {
  name: 'App',
};
</script>